export const ADD_INGREDIENT = "ADD_INGREDIENT";
export const REMOVE_INGREDIENT = "REMOVE_INGREDIENT";
export const FETCH_INGREDIENTS_START = "FETCH_INGREDIENTS_START";
export const FETCH_INGREDIENTS_SUCCESS = "FETCH_INGREDIENTS_SUCCESS";
export const FETCH_INGREDIENTS_FAILED = "FETCH_INGREDIENTS_FAILED";

export const PURCHAGE_BURGER_START = "PURCHAGE_BURGER_START";
export const PURCHAGE_BURGER_SUCCESS = "PURCHAGE_BURGER_SUCCESS";
export const PURCHAGE_BURGER_FAIL = "PURCHAGE_BURGER_FAIL";
export const PURCHASE_INIT = "PURCHASE_INIT";
export const PURCHAGE_BURGER = "PURCHAGE_BURGER";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_START = "FETCH_ORDERS_START";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";

export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_USER = "AUTH_USER";
